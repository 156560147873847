import React from "react"
import { Box, Text, SimpleGrid, Flex, Image } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Feature1Image from "@/assets/images/userresearch/research/image-1.png"
import { headerHeight } from "@/utils/userresearch/constant"

const features = [
  { image: Feature1Image },
  { image: '' },

]

const Research = () => {
  const { t } = useTranslation()

  return (
    <Box
      as="section"
      id="Research"
      sx={{ scrollMarginTop: headerHeight }}
      width="full"
      position="relative"
      backgroundColor="#FAFAFA"
    >
      <Box mx="auto" maxW="5xl">
        <SimpleGrid
          px="6"
          py="20"
          columns={{ base: 1, md: 2 }}
          spacing="12"
          textAlign="center"
          pt="20px"
          pb={{ base: "0px"}}
        >
          {features.map(({ image }, index) => (
            <Flex
              direction="column"
              key={`feature-${index + 1}`}
              alignItems="start"
              px="4"
              height="120%"
              marginTop="35px"
              marginLeft={{ base: "0px", sm: "72px" }}
            >
            {image && <Image
                src={image}
                alt={ image && `${t(
                  `home-page.research.features.${index-1}.name`
                )} logo`}
                width={{ base: "320px", sm: "320px" }}
                height={{ base: "215px", sm: "215px" }}
              /> }

             { !image &&  <Text fontFamily="Noto Sans" lineHeight="38px" fontStyle="normal" fontSize="24px" fontWeight="bold" color="rgba(15, 15, 15, 0.97);">
                {t(
                  `home-page.research.features.${index-1}.name`
                )}
              </Text> }

             {!image && <Text  fontFamily="Noto Sans" lineHeight="26px" fontStyle="normal" fontSize="16px" fontWeight="400" color=" rgba(15, 15, 15, 0.8);" pt="20px" textAlign="initial">
                {t(
                  `home-page.research.features.${index-1}.description`
                )}
              </Text> }
            </Flex>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default Research
